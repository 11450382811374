<template>
  <div class="ayuda">
    <headNav :activeNav="'ayuda'"></headNav>
    <div class="banner"></div>
    <div class="content">
        <p class="title" style="font-size:30px !important">Centro de ayuda</p>
        <div class="line"></div>
        <ul class="contList">
          <li v-for="(item,index) in list" :key="index">
            <img src="../assets/image/wh_ico.png" alt="">
            <div>
              <p class="title">{{item.title}}</p>
              <p v-for="(sm,smid) in item.cont" :key="smid">
                <span v-if="changeBtn==1&&index==1&&smid==3" style="color:#000">NOTA:</span>{{sm}}
              </p>
            </div>
          </li>
        </ul>
        <div class="btnList">
          <div v-for="item in [1,2,3,4]" :key="item" @click="change(item)" :class="changeBtn==item?'activeBtn':''">{{item}}</div>
        </div>
    </div>
    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/ayuda.scss";

export default {
  name: "ayuda",
  components: { headNav, footPage },
  data() {
    return {
      title:'Centro de ayuda',
      activeNames:[],
      changeBtn:1,
      activeName:'one',
      list:[
        {
          title:'Requisito indispensable:',
          cont:["Edad: mayor de 18 años"]
        },
        {
          title:'Trámites indispensables:',
          cont:[
            "1) Tener su IFE/INE en la mano",
            "2) Tener una cuenta de débito de CUALQUIER banco y que esté a SU nombre",
            '3) Llenar su solicitud en la aplicación',
            'Recuerde que no pedimos ninguna clase de anticipos y que la única forma de hacer su solicitud es por medio de la aplicación. TODO EL PROCESO ES GRATUITO'
            ]
        }
      ],
    }
  },
  methods:{
    change(id){
      this.changeBtn=id
      if(id==1){
        this.list=[
       {
          title:'Requisito indispensable:',
          cont:["Edad: mayor de 18 años"]
        },
        {
          title:'Trámites indispensables:',
          cont:[
            "1) Tener su IFE/INE en la mano",
            "2) Tener una cuenta de débito de CUALQUIER banco y que esté a SU nombre",
            '3) Llenar su solicitud en la aplicación',
            'Recuerde que no pedimos ninguna clase de anticipos y que la única forma de hacer su solicitud es por medio de la aplicación. TODO EL PROCESO ES GRATUITO'
            ]
        }
      ]
      }else if(id==2){
        this.list=[
          {
            title:'¿Cómo puedo solicitar mi primer préstamo? ',
            cont:['Entre a la App y regístrese con su número telefónico (le recomendamos crear una contraseña a la vez) y complete los datos básicos. Después de la autorización, de clic en el botón de “CONFIRMAR”, y el préstamo llegará a su cuenta bancaria. '
            ]
          },
          {
            title:'¿Cómo puedo solicitar de nuevo un préstamo? ',
            cont:['Si le rechazaron su última solicitud, puede volver a solicitar después de 7 días; Una vez que se acredite su pago podrá solicitar un segundo préstamo ¡de inmediato! Y esta vez disfrutará de un monto mayor con un costo menor. ']
          },
          {
            title:'¿Cómo tener otro préstamo mientras tengo uno activo?  ',
            cont:['Lamentablemente no puede pedir otro préstamo mientras tiene un activo. Debería liquidar su préstamo activo para poder solicitar otro. Eso sí, una vez que liquide, podrá solicitar otro de forma inmedia. ']
          },
          {
            title:'¿Cuánto tiempo tengo para confirmar mi préstamo en la App? ',
            cont:['Le quedan TRES días para confirmar su préstamo. Le recomendamos dar clic en “CONFIRMAR” lo antes posible. Cuando se convierta en nuestro cliente Premium, disfrutará de un monto mayor con un costo menor. ']
          },
           {
            title:'¿Cuánto tiempo tarda en depositar? ',
            cont:['Procesaremos su solicitud lo antes posible. Una vez aprobada tu solicitud, el depósito se completará en unos minutos. Sin embargo, debido a la diferencia en el horario laboral del Banco de México, puede haber un retraso en su transferencia. No se preocupe, si tiene circunstancias especiales, puede contactarnos al 5585263904 de 9:00 a 18:00 en horario laboral o al correo vivavida.prestamos@gmail.com. ']
          },
           {
            title:'¿Qué puedo hacer si falla el depósito?',
            cont:['Estimado cliente, en este caso puede intentar comprobar si hay un error en el préstamo, o hay un problema con la recepción de su cuenta, y si el estado de su cuenta bancaria es normal. Si tiene circunstancias especiales, puede contactarnos al 5585263904 de 9:00 a 18:00 en horario laboral o al correo vivavida.prestamos@gmail.com. Le proporcionaremos la información más detallada y le brindaremos servicios más personalizados. ']
          },
           {
            title:'¿Cómo usan la información que lleno en la solicitud de préstamo? ',
            cont:['VIVA VIDA usa sus respuestas para determinar si es candidato a un préstamo de nuestra App. Toda su información se encuentra segura y protegida. Nunca proporcionaremos su información a terceros sin su permiso. Puede leer nuestro Aviso de Privacidad dentro de la App.']
          },
        ]
      }else if(id==3){
        this.list=[
          {
            title:'¿Cómo pago mi préstamo en OXXO? ',
            cont:[
              '(1) Abra la APP y verá su número de referencia de pago OXXO, es único y personal. Guárdelo ya que ese número será lo mismo para todos sus pagos en OXXO; ',
              '(2) Realice su pago por OXXO PAY en la tienda OXXO;',
              '(3) De el número de referencia;',
              '(4) Confirme que el beneficiario seaVIVA VIDA;',
              '(5) Pague en efectivo y guarde el ticket, ¡y listo! '
            ]
          },
           {
            title:'¿Qué puedo hacer si no puedo pagar en OXXO?',
            cont:[
              '(1) Si tiene problemas al pagar su préstamo en OXXO, ¡no se preocupe! Le recomendamos pagar por transferencia electrónica, que es más fácil y econónica;',
              '(2) En la aplicación debería poner la información de transferencia únicamente por la primera vez (el número de CLABE que ingrese comienza con 646);',
              '(3) Recuerde que no importa si paga desde la aplicación de su banco o en OXXO, puede pedir otro préstamo inmediatamente después de ver reflejado su pago. '

]
          },
           {
            title:'¿Cómo pago mi préstamo a SPEI? ',
            cont:[
          '(1) Abre la aplicación de su banco o ingrese a página de internet y seleccione "Transferir";',
          '(2) Seleccione la cuenta origen; ',
          '(3) Seleccione "Agregar un nuevo destinatario"; ',
          '(4) Ingrese el número de cuenta CLABE deVIVA VIDA; ',
          '(5) Ingrese "VIVA VIDA" como nombre del Beneficiario y nombre de alias del contracto; ',
          '(6) Seleccione el monto de su pago y reálicelo;',
          '(7) ¡Transferencia exitosa! Los pagos tardarán hasta 2 horas en reflejarse enVIVA VIDA, también puede copiar directamente la información de transferencia desdeVIVA VIDA. ',
          // '*Por ejemplo: Los datos que debe colocar en su banca móvil son: BANCO: STP; BENEFICIARIO:VIVA VIDA; CONCEPTO: PAGO DE PRÉSTAMO; CLABE: 646***************. '
          ]
          },
        ]

      }else{
        this.list=[
          {
            title:'Me rechazaron mi préstamo, ¿por qué?',
            cont:['Lamentamos que su solicitud haya sido rechazada; esto significa que no podrá utilizar nuestros servicios por ahora. ¡No se preocupe! En esta ocasión no fue candidato para un préstamo, pero esperamos que pronto se una aVIVA VIDA. En cuanto sea elegible de nuevo, la App le mandará una notificación a su celular para realizar una nueva solicitud. ']
          },
          {
            title:'Realicé el pago a tiempo (por adelantado), y me rechazaron mi próximo préstamo, ¿por qué? ',
            cont:[
'Lamentamos que su solicitud haya sido rechazada. Hay varias razones por las que esto sucede. Para tener una mejor comprensión de lo sucedido, puede contactarnos al 5585263904 de 9:00 a 18:00 en horario laboral o al correo vivavida.prestamos@gmail.com, indicando nombre y telefono registrado para que podamos brindarle un servicio personalizado.'           ]
          },
          {
            title:'Realicé el pago, sin embargo, en la App no ha disminuido la cantidad pendiente de pago, ¿qué hago? ',
            cont:[
'Lamentamos que se haya encontrado con este problema. Favor de tener más paciencia, porque los pagos en OXXO pueden tardar hasta 24 horas en verse reflejados. Y las transferencias interbancarias pueden tardar hasta 72 horas. Si pasado ese tiempo, no ve su pago reflejado, por favor contáctenos al teléfono 5585263904 en un horario de 9:00 am a 6:00 pm o por correo vivavida.prestamos@gmail.com con copia de su comprobante de pago y con gusto le ayudamos. '            ]
          },
          {
            title:'Accidentalmente pagué en exceso, ¿qué hago?',
            cont:[
'No se preocupe, el dinero en exceso se depositará automáticamente en el saldo de su cuenta deVIVA VIDA y se puede utilizar para el pago. '            ]
          },
          {
            title:'Me quedo bloqueado al completar la información y no puedo continuar con el siguiente paso, ¿qué hago?  ',
            cont:[ 'Lamentamos que se haya encontrado con este problema. Favor de verificar su red o vuelva a descargar la App para intentar completar su información. Si aún no se resuelve, envíe un correo electrónico a vivavida.prestamos@gmail.com con copia de su comprobante, le responderemos lo antes posible. ']
          },
          {
            title:'La App me devolvió una interfaz de error, ¿qué hago?',
            cont:[
`En este caso, le recomendamos que primero compruebe si la red funciona con normalidad o intente actualizar la App a la última versión. Si el problema persiste, puede contactarnos al 5585263904 de 9:00 a 18:00 en horario laboral o al correo vivavida.prestamos@gmail.com y proporcione la siguiente información: su nombre completo, ID de cliente, descripción de problema y la captura de pantalla que muestra la falla. `            ]
          },
          {
            title:'Quiero cambiar mi cuenta bancaria, ¿qué hago?  ',
            cont:[
              'Si no ha solicitado un préstamo, puede modificar la cuenta bancaria en la página “Yo-Información personal”; si ya ha enviado la solicitud, no necesita cambiar su tarjeta bancaria, puede usar cualquier tarjeta bancaria para realizar el pago; cuando liquide su pedido pendiente, también puede modificar la cuenta bancaria en la página “Yo-Información personal” antes de volver a solicitar un préstamo. '
            ]
          },
          {
            title:'Quiero cambiar mi número de celular, ¿qué hago? ',
            cont:['Si desea cambiar su número de celular cuando está en el paso de registro, puede registrarse con su nuevo número de celular; si le queda un pedido por pagar, puede iniciar la sesión con su número de celular y la contraseña correspondiente; Si tiene un caso particular, por favor contáctenos al teléfono 5585263904 en un horario de 9:00 am a 6:00 pm o por correo vivavida.prestamos@gmail.com. ']
          },
          {
            title:'¿Cómo completar la información bancaria para el pago del préstamo? ',
            cont:['Primero, copie la cuenta de pago en la interfaz de pago de App. Luego, abra su software de pago de terceros y complete la siguiente información en secuencia. Los datos que debe colocar en su banca móvil son: BANCO: STP, BENEFICIARIO:VIVA VIDA, CONCEPTO: PAGO DE PRÉSTAMO. ']
          },
          {
            title:'¿Cómo puedo aumentar el monto de préstamo? ',
            cont:['Nuestro sistema evalúa el monto según su crédito. Por favor, mantenga un historial crediticio bueno y constante, lo que le ayudará a aumentar el monto de su préstamo. Sin embargo, estos datos se basan en el puntaje integral del sistema y no se permiten factores humanos. Esperamos su comprensión. Si tiene circunstancias especiales, puede contactarnos al 5585263904 de 9:00 a 18:00 en horario laboral o al correo vivavida.prestamos@gmail.com. ']
          }
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ayuda{
  min-height: 100vh;
  flex-direction: column;
  display: flex;
  background: #fbfbfb;
}

.content{
  flex: 1;
  z-index: 99;
  width: 1200px;
  height: 956px;
  margin: 164px auto 0;
  background-color: #ffffff;
  padding-top: 37px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 60px;
  align-items: center;
  position: relative;
  padding-bottom: 160px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.answer span {
  font-weight: 700;
}

button{
  display: inline-block;
  padding: 12px 34px;
  font-weight: 400;
   border: none;
   color: #E7E6E6;
   background: #fff;
   cursor: pointer;
    font-size: 18px;
  border-radius: 24px;
  box-shadow: 0px 2px 11px 0px #E7E6E6;;
}
.is-active button{
  box-shadow: 5px 6px 15px 0px rgba(11, 146, 36, 0.37);;
  background: linear-gradient(90deg, #34C744 0%, #01851C 100%);
  color: #fff;

}
ul{
  list-style: none;
}
.btnList{
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 40px;
  div{
    width: 50px;
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    cursor: pointer;
    margin-right: 20px;
  }
  .activeBtn{
  background: rgb(28, 131, 255);
  color: #fff;
}
}
.contList{
  padding: 0 50px;
  li{
    display: flex;
    align-items: flex-start;
    line-height: 40px;
    color: #999;
    font-size: 18px;
    padding-bottom: 19px;
    border-bottom: 2px solid #efefef;
    .title{
      margin-bottom: 10px;
      color: #000;
    }
    img{
      width: 30px;
      position: relative;
      top:16px;
      margin-right: 15px;
    }
  }
}

</style>
