<template>
  <div class="Inicio">
    <div class="banner">
      <div class="mainContent">
        <!--https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.deposito.inmediato-->
        <!--<a
            href="https://bit.ly/49xwybG"
            target="_blank"
            class="playBtn"
        ><img src="@/assets/image/playBtnv1.png" alt=""
        /></a>-->
        <a
          href="https://vivavida.onelink.me/v0MW/f0ue675w"
          target="_blank"
          class="playBtn"
          ><img src="@/assets/image/playBtnv1.png" alt=""
        /></a>
      </div>
    </div>

    <div class="p2">
      <div class="mainContent twoContent">
        <div class="title">
          <img src="../assets/image/jk_ico.png" alt="" />Solicitar un préstamo
        </div>
        <div class="loan-content-box">
          <div class="loan-select-box">
            <!-- <div class="loan-select-item">7 Días</div> -->
            <div
              class="loan-select-item"
              :class="isActive == 61 ? 'selected' : ''"
              @click="getDays(61)"
            >
              61 Días
            </div>
            <div
              class="loan-select-item"
              :class="isActive == 90 ? 'selected' : ''"
              @click="getDays(90)"
            >
              90 Días
            </div>
            <div
              class="loan-select-item"
              :class="isActive == 180 ? 'selected' : ''"
              @click="getDays(180)"
            >
              180 Días
            </div>
          </div>
          <div class="moneybox">
            $ <span id="money">{{ dealNumber(sliderValue) }}</span>
          </div>
          <div class="sliderBox">
            <el-slider
              v-model="sliderValue"
              :min="1000"
              :max="20000"
              :show-tooltip="false"
            ></el-slider>
            <div class="range">
              <p>$ <span>1,000</span></p>
              <p>$ <span>20,000</span></p>
            </div>
          </div>

          <div class="dateBox">
            <img src="@/assets/image/date_icon.png" alt="" />
            <div class="date">
              Fecha de vencimiento：<span>{{ addDate }}</span>
            </div>
          </div>

          <a
            href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.deposito.inmediato"
            target="_blank"
            class="downloadBtn"
            ><button class="solicitarBtn">Solicitar un préstamo</button></a
          >
        </div>
      </div>
    </div>

    <div class="mgm_wrap">
      <div class="mgm_home_banner_img">
        <img src="../assets/image/InvitaYgana_bgimg.png" alt="">
      </div>
      <div class="mgm_home_view">
          <div class="mgm_home_cont">
            <div class="mgm_home_title">
              <img src="../assets/image/InvitaYgana_title.png" alt="">
            </div>
            <div class="mgm_home_dview">
              <div class="mgm_home_dvleft">
                <img src="../assets/image/InvitaYgana_cont.png" alt="">
                <div class="mgm_home_dvbtns">
                  <div class="mgm_home_dvbtn">Invitar y compartir</div>
                </div>
              </div>
              <div class="mgm_home_dvright">
                <img src="../assets/image/InvitaYgana_learn.png" alt="">
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="p3">
      <div class="mainContent">
        <div class="title_wrap">
          <div class="title_wrap_use">
            <div class="title_icon_dv">
              <img src="../assets/image/home_solid_left.png" alt="">
            </div>
            <div class="title_cont">
                <img src="../assets/image/wh_ico.png" alt="" /> ¿Por qué elegirnos?
            </div>
            <div class="title_icon_dv">
              <img src="../assets/image/home_solid_right.png" alt="">
            </div>
          </div>
        </div>

        <div class="content">
          <div class="item">
            <div class="hometop_item_imgs01">
              <img src="@/assets/image/hometop_item_imgs01.png" alt="" />
            </div>
            <p class="item-title">Monto alto del préstamo</p>
            <div class="line"></div>
            <p class="item-subtitle">
              El monto del préstamo puede ser de hasta <span>$20,000</span>
            </p>
          </div>
          <div class="item">
            <div class="hometop_item_imgs02">
              <img src="@/assets/image/hometop_item_imgs02.png" alt="" />
            </div>
            <p class="item-title">Proceso de solicitud sencillo</p>
            <div class="line"></div>
            <p class="item-subtitle">Aplicación rápida</p>
            <p class="item-subtitle">Tasa de aprobación alta</p>
            <p class="item-subtitle">Depósito rápido</p>
          </div>
          <div class="item">
            <div class="hometop_item_imgs03">
              <img src="@/assets/image/hometop_item_imgs03.png" alt="" />
            </div>
            <p class="item-title">100% protección de seguridad de datos</p>
            <div class="line"></div>
            <p style="width: 300px" class="item-subtitle">
             VIVA VIDA protege la seguridad
            </p>
            <p style="width: 300px" class="item-subtitle">
              de sus datos las 24 horas del día,
            </p>
            <p style="width: 300px" class="item-subtitle">
              abriendo el nivel más alto de protección
            </p>
            <p style="width: 300px" class="item-subtitle">
              de seguridad de privacidad de datos para usted.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="p4">
      <div class="mainContent">
        <div class="title">
          <img src="../assets/image/dh_ico.png" alt="" /> Proceso de préstamo
        </div>

        <div class="content">
          <div
            class="item"
            :class="changeNum == 1 ? 'activeItem' : ''"
            @click="changeList(1)"
          >
            <img
              v-if="changeNum == 1"
              src="../assets/image/oneList1_new.png"
              alt=""
            />
            <img v-else src="../assets/image/p4_new01.png" alt="" />
            <p class="item-title">Descarga la App</p>

            <p class="item-subtitle">
              Vaya a
              <!--<a
                style="color: #0060d5"
                href="https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.deposito.inmediato"
                target="_blank"
                ><u>GooglePaly</u></a
              >-->
              GooglePaly
              para descargar
            </p>
            <p class="item-subtitle">VIVA VIDA</p>
          </div>
          <div
            class="item"
            :class="changeNum == 2 ? 'activeItem' : ''"
            @click="changeList(2)"
          >
            <img
              v-if="changeNum == 2"
              src="../assets/image/oneList2_new.png"
              alt=""
            />
            <img v-else src="../assets/image/p4_new02.png" alt="" />
            <p class="item-title">Proceso de solicitud sencillo</p>
            <p class="item-subtitle">*Cree su cuenta</p>
            <p class="item-subtitle">* Solo 4 pasos y no más de 3 minutos</p>
            <p class="item-subtitle">para solicitar su préstamo</p>
          </div>
          <div
            class="item"
            :class="changeNum == 3 ? 'activeItem' : ''"
            @click="changeList(3)"
          >
            <img
              v-if="changeNum == 3"
              src="../assets/image/oneList3_new.png"
              alt=""
            />
            <img v-else src="../assets/image/p4_new03.png" alt="" />
            <p class="item-title">
              <span style="color: #0060d5">100%</span> solicitud online
            </p>
            <p class="item-subtitle">
              * Aplicación móvil en línea las 24 horas
            </p>
            <p class="item-subtitle">
              en cualquier momento y en cualquier lugar
            </p>
          </div>
          <div
            class="item"
            :class="changeNum == 4 ? 'activeItem' : ''"
            @click="changeList(4)"
          >
            <img
              v-if="changeNum == 4"
              src="../assets/image/oneList4_new.png"
              alt=""
            />
            <img v-else src="../assets/image/p4_new04.png" alt="" />
            <p class="item-title">Depósito exitoso</p>
            <p class="item-subtitle">
              * El préstamo se depositará directamente
            </p>
            <p class="item-subtitle">en su cuenta bancaria</p>
            <p class="item-subtitle">* Después de realizar su pago a tiempo.</p>
            <p class="item-subtitle">Puede obtener un préstamo más alto,</p>
            <p class="item-subtitle">
              el tipo de interés más bajo y los términos de préstamo más
              flexibles.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="p6" style="background: #f5f5f5">
      <div class="mainContent" style="text-align: center">
        <div class="title">
          <img src="../assets/image/kf_ico.png" alt="" />Contáctanos
        </div>
        <p class="item-title">
          Nombre de la empresa：SUSU SOUL, S.A DE C.V.
        </p>
        <p class="item-title" style="margin-top: 20px;">
          Dirección de la empresa：CLL VERSALLES 15, PISO 1 LT, JUAREZ, MEXICO, CIUDAD DE MEXICO, C.P. 06600
        </p>
        <p class="item-title" style="margin-top: 20px;">
          Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de
          atención al cliente.
        </p>
        <ul>
          <li>
            <img src="../assets/image/sj_ico.png" alt="" />
            <div>
              <p>Atención al Cliente：</p>
              <p style="color: #333">5585263904</p>
            </div>
          </li>
          <li>
            <img src="../assets/image/ico_2.png" alt="" />
            <div>
              <p>Email：</p>
              <p style="color: #333">vivavida.prestamos@gmail.com</p>
            </div>
          </li>
          <li>
            <img src="../assets/image/tm_ico.png" alt="" />
            <div>
              <p>Tiempo laboral：</p>
              <p style="color: #333">De lunes a viernes 9:00-18:40</p>
              <p style="color: #333">De sábado a domingo 9:00-14:40</p>
            </div>
          </li>
        </ul>
      </div>
    </div>-->

    <div class="vvcontact_wrap" style="background: #232323;">
        <div class="vvcontact_cont">
            <div class="vvcontact_title">
                <div class="vvcontact_tname">Contáctanos</div>
                <div class="vvcontact_tdesc">Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de atención al cliente.</div>
            </div>
            <div class="vvcontact_view">
                <div class="vvcontact_vleft">
                  <div class="vvcontact_vitems" style="min-height: 100px;">
                    <div class="vvcontact_vitop">
                      <div class="vvcontact_viticon">
                        <img src="../assets/image/viticon_01.png" alt="" />
                      </div>
                      <div class="vvcontact_vitname">Email</div>
                    </div>
                    <div class="vvcontact_vidown">
                      <div class="vvcontact_vidtext">vivavida.prestamos@gmail.com</div>
                    </div>
                  </div>

                  <div class="vvcontact_vitems" style="min-height: 100px;">
                    <div class="vvcontact_vitop">
                      <div class="vvcontact_viticon">
                        <img src="../assets/image/viticon_03.png" alt="" />
                      </div>
                      <div class="vvcontact_vitname">Atención al Cliente:</div>
                    </div>
                    <div class="vvcontact_vidown">
                      <div class="vvcontact_vidtext">+525585263904</div>
                    </div>
                  </div>

                  <div class="vvcontact_vitems" style="min-height: 100px;">
                    <div class="vvcontact_vitop">
                      <div class="vvcontact_viticon">
                        <img src="../assets/image/viticon_05.png" alt="" />
                      </div>
                      <div class="vvcontact_vitname">whatsapp：</div>
                    </div>
                    <div class="vvcontact_vidown">
                      <div class="vvcontact_vidtext">+525525278620</div>
                    </div>
                  </div>

                </div>
                <div class="vvcontact_vright">
                  <div class="vvcontact_vitems">
                    <div class="vvcontact_vitop">
                      <div class="vvcontact_viticon">
                        <img src="../assets/image/viticon_02.png" alt="" />
                      </div>
                      <div class="vvcontact_vitname">Tiempo laboral:</div>
                    </div>
                    <div class="vvcontact_vidown">
                      <div class="vvcontact_vidtext">De lunes a viernes 9:00-18:40</div>
                      <div class="vvcontact_vidtext">De sábado a domingo 9:00-14:40</div>
                    </div>
                  </div>
                  <div class="vvcontact_vitems">
                    <div class="vvcontact_vitop">
                      <div class="vvcontact_viticon">
                        <img src="../assets/image/viticon_04.png" alt="" />
                      </div>
                      <div class="vvcontact_vitname">Especialistas en ventas:</div>
                    </div>
                    <div class="vvcontact_vidown">
                      <div class="vvcontact_vidview">
                        <div class="vvcontact_vidvleft">
                          <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Victor Garcia:</span> </div>
                          <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Jimena Hernandez:</span>  </div>
                          <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Isaac Bernal:</span> </div>
                        </div>
                        <div class="vvcontact_vidvright">
                          <!--<div class="vvcontact_vidtext">+522215908643</div>-->
                          <!--<div class="vvcontact_vidtext">+527221161259</div>-->
                          <!--<div class="vvcontact_vidtext">+527351638946</div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="vvcontact_solid"></div>

    <el-dialog
      title="AVISO IMPORTANTE"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <span slot="footer" class="dialog-footer">
        <p>
          Te informarnos que el número de referencia o cuenta para pagar tu
          crédito es únicamente el que aparece en tu acceso a la aplicación.
         VIVA VIDA nunca te contactará por otro medio para proporcionarte una
          cuenta distinta a la que se indica en tu aplicación. Para evitar que
          seas víctima de algún fraude, te sugerimos ser cuidadoso y verificar
          el número de referencia o cuenta en la que pagarás tu crédito. Para
          cualquier duda o aclaración puedes comunicarte con nuestro
          departamento de Atención a Clientes.
        </p>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import "./scss/Inicio.scss";

export default {
  name: "Inicio",
  data() {
    return {
      changeNum: 0,
      sliderValue: 5000,
      currentdate: "",
      addDate: "",
      isActive: 61,
      dialogVisible: true,
    };
  },
  created() {
    this.getNowFormatDate();
    if (this.$route.query.dialogVisible == 1) {
      this.dialogVisible = false;
    }
  },
  methods: {
    changeList(index) {
      this.changeNum = index;
    },
    getDays(day) {
      this.isActive = day;
      this.dateAddDays(this.currentdate, day);
    },
    dealNumber(num) {
      if (num != 0 && num) {
        num = num + "";
        let splitList = num.split(".");
        //将整数部分拆解为单个数字的数组倒序，然后拼装为新的数组，每3位数字进行一次匹配
        let intStrList = splitList[0]
          .split("")
          .reverse()
          .join("")
          .match(/(\d{1,3})/g);
        //将匹配后的数组用，拼接，再拆解为单个数字的数组，反转为原先数组形式，拼接为完整数字
        let intStr = intStrList.join(",").split("").reverse().join("");
        return intStr;
      }
      return num;
    },
    dateAddDays(dateStr, dayCount) {
      var tempDate = new Date(dateStr.replace(/-/g, "/")); //把日期字符串转换成日期格式
      var resultDate = new Date((tempDate / 1000 + 86400 * dayCount) * 1000); //增加n天后的日期
      let mounth = (resultDate.getMonth() + 1).toString();
      if (mounth.length == 1) {
        mounth = "0" + mounth;
      }
      let today = resultDate.getDate().toString();
      if (today.length == 1) {
        today = "0" + today;
      }

      var resultDateStr = today + "-" + mounth + "-" + resultDate.getFullYear(); //将日期转化为字符串格式
      this.addDate = resultDateStr;
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.currentdate = currentdate;
      this.getDays(90);
    },
  },
};
</script>

<style lang="scss">
.p4 .item {
  cursor: pointer;
}
.activeItem {
  background: url("../assets/image/oneListBg.png") !important;
  background-position: cover;
  background-size: 100% 100% !important;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  color: #fff;
  .item-title {
    color: #fff !important;
  }
  .item-subtitle {
    color: #ffffff !important;
  }
  .number {
    color: #159f3f;
    background: linear-gradient(360deg, #c9ffd8 0%, #ffffff 100%);
    box-shadow: 5px 3px 13px 0px rgba(30, 179, 72, 0.32),
      0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  }
  a,
  span {
    color: #0060d5 !important;
  }
}
video {
  width: 1000px !important;
  height: auto !important;
  margin-top: 100px !important;
}
.p4 .item-subtitle {
  line-height: 24px;
}
.playBtn img {
  height: auto !important;
}
  .title_wrap{
    width: 800px;
    height: 188px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 50px;
    margin: 0 auto;
  }
  .title_wrap_use{
    width: 100%;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title_icon_dv{
    width: 190px;
    height: 3px;
  }
  .title_icon_dv img{
    display: block;
    width: 100%;
  }
  .title_cont{
    width: 340px;
    height: 42px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .Inicio .title_cont img {
    width: 28px;
    height: 28px;
    display: block;
    margin-right: 18px;
  }
  .hometop_item_imgs01{
    width: 144px;
    height: 167px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 19px;
  }
  .hometop_item_imgs01 img{
    width: 144px;
    height: 148px;
  }
  .hometop_item_imgs02{
    width: 127px;
    height: 167px;
  }
  .hometop_item_imgs02 img{
    width: 127px;
    height: 167px;
  }
  .hometop_item_imgs03{
    width: 144px;
    height: 167px;
    box-sizing: border-box;
    padding-top: 5px;
  }
  .hometop_item_imgs03 img{
    width: 144px;
    height: 162px;
  }
  .mgm_wrap{
    width: 100%;
    height: 922px;
    position:relative;
    overflow:hidden;
    z-index: 2;
    padding-bottom: 88px;
    background: #ffffff;
  }
  .mgm_home_banner_img{
    width: 1920px;
    position:absolute;
    left:50%;
    margin-left:-960px;
  }
  .mgm_home_view{
    width: 100%;
    height: 922px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .mgm_home_cont{
    width: 1120px;
    height: 922px;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: 16px;
  }
  .mgm_home_title{
    width: 617px;
    height: 50px;
    margin-top: 80px;
    margin-left: 236px;
  }
  .mgm_home_title img{
    display: block;
    width: 617px;
    height: 50px;
  }
  .mgm_home_dview{
    width: 100%;
    height: 672px;
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
  }
  .mgm_home_dvleft{
    width: 619px;
    height: 672px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 16px;
  }
  .mgm_home_dvleft img{
    display: block;
    width: 100%;
    height: 508px;
  }
  .mgm_home_dvbtns{
    width: 300px;
    height: 60px;
    margin-top: 64px;
    background: linear-gradient(180deg, #5FADFE 0%, #167FFF 100%);
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mgm_home_dvbtn{
    width: 100%;
    height: auto;
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
  }
  .mgm_home_dvright{
    width: 350px;
    height: 672px;
  }
  .mgm_home_dvright img{
    display: block;
    width: 350px;
    height: 672px;
  }
  .vvcontact_wrap{
    width: 100%;
    height: 564px;
  }
  .vvcontact_solid{
    width: 100%;
    height: 1px;
    background: #2f2f2f;
  }
  .vvcontact_cont{
    width: 1290px;
    height: 564px;
    margin: 0 auto;
  }
  .vvcontact_title{
    width: 100%;
    height: auto;
    padding-top: 84px;
  }
  .vvcontact_tname{
    width: 100%;
    height: 34px;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: left;
  }
  .vvcontact_tdesc{
    width: 100%;
    height: auto;
    font-size: 16px;
    color: #828282;
    line-height: 22px;
    text-align: left;
  }
  .vvcontact_view{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding-top: 84px;
  }
  .vvcontact_vleft{
    width: 645px;
    height: auto;
  }
  .vvcontact_vright{
    width: 400px;
    height: auto;
  }
  .vvcontact_vitems{
    width: 100%;
    height: auto;
    min-height: 126px;
  }
  .vvcontact_vitop{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .vvcontact_viticon{
    width: 20px;
    height: 20px;
  }
  .vvcontact_viticon img{
    display: block;
    width: 20px;
    height: 20px;
  }
  .vvcontact_vitname{
    width: auto;
    height: 25px;
    font-size: 18px;
    color: #828282;
    line-height: 25px;
    text-align: left;
    margin-left: 8px;
  }
  .vvcontact_vidown{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px 0 0 28px;
  }
  .vvcontact_vidtext{
    width: 100%;
    height: 35px;
    font-weight: 400;
    font-size: 18px;
    color: #DEDEDE;
    line-height: 25px;
    text-align: left;
  }
  .vvcontact_vidview{
    width: 310px;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
</style>

