<template>
  <div class="foot">
    <div class="footLeft">
      <img style="width: 181px" src="../assets/image/logo2.png" alt="" />
    </div>
    <div  class="footLeft">

      <!--https://play.google.com/store/apps/details?id=credit.prestamos.personale.cash.deposito.inmediato-->
      <a
        href="https://vivavida.onelink.me/v0MW/f0ue675w"
        target="_blank"
        class="playBtn"
        ><img src="@/assets/image/xz_ico.png" alt=""
      /></a>

      <!--<a
         href="https://bit.ly/49xwybG"
         target="_blank"
         class="playBtn"
      ><img src="@/assets/image/playBtnv1.png" alt=""
      /></a>-->
    </div>
    <div class="footRight">
      <div class="top">
        <img src="../assets/image/youxiang.png" /> Queja de cobranza:
      </div>
      <div>vivavida.cobranza.mx@gmail.com</div>
    </div>
  </div>
</template>

<script>
import "./scss/foot.scss";
export default {
  name: "footPage",
  data() {
    return {};
  },
};
</script>
