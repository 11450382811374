<template>
  <div class="transparenciaOne">
    <headNav activeNav="transparencia"></headNav>
    <img src="../assets/image/5_banner.png" class="banner" />
    <div class="wrapper">
      <div class="wrapper_top">
        <h1>TRANSPARENCIA DEVIVA VIDA</h1>
        <div class="top_common right">
          <img
            src="../assets/image/transparencia_one.png"
            class="transparencia_one"
          />
          <div>
            <div class="title">
              <img src="../assets/image/line.png" class="line" />
              Cuidado con las apps de préstamos
            </div>
            <p>
              Adherimos normas de privacidad y transparencia en todas nuestras
              operaciones. Hemos detectado que algunas organizaciones que operan
              en México se hacen pasar por nosotros en redes sociales y
              aplicaciones con el objetivo de cometer fraudes a los usuarios, si
              detectas comportamientos irregulares, ¡cuidado! No se trata de
              nuestra app oficial y estás en riesgo de sufrir un fraude.
            </p>
          </div>
        </div>
        <div class="top_common left">
          <div>
            <div class="title">
              <img src="../assets/image/line.png" class="line" />
              Protección de datos
            </div>
            <p>
              EnVIVA VIDA estamos comprometidos con la Seguridad y Privacidad
              de la Información al integrar procesos, tecnología y personal que
              nos permita garantizar la confidencialidad, disponibilidad e
              integridad de nuestros clientes.
            </p>
            <p>
              Toda la información que solicitamos es plena y exclusivamente para
              verificar sus datos para la prestación de nuestros productos y
              servicios, para dar continuidad a sus requerimientos y/o
              necesidades, para poder atender sus dudas, quejas, sugerencias y/o
              agradecimientos.
            </p>
            <ul>
              <li>Datos de identificación,</li>
              <li>Datos de contacto,</li>
              <li>
                Información sobre el producto y/o servicio que le interesa.
              </li>
            </ul>
          </div>
          <img
            src="../assets/image/transparencia_two.png"
            class="transparencia_two"
          />
        </div>
      </div>
      <img
        src="../assets/image/transparencia_three.png"
        style="margin-bottom: 100px"
      />
      <div class="padding">
        <div class="wrapper_middle">
          <h1>Presta atención a los siguientes tres casos:</h1>
          <div class="card">
            <h6>Llamadas personalizadas</h6>
            <hr />
            <div>
              <p>
                En <span style="font-weight: 600">VIVA VIDA</span> estamo
                estamos trabajando para ti, nuestro diseño de solicitud es muy
                sencillo y lo puedes realizar desde tu teléfono, no contamos con
                asesores para que puedas realizar tu solicitud.
              </p>
              <p>
                Si por algún medio “red social, WhatsApp o mensaje de texto” te
                contactan ofreciendo un préstamo ¡Cuidado! Podría ser un fraude,
                nuestro personal no puede aprobar su solicitud por estos medios.
              </p>
              <p>
                Llena tu solicitud en la App de
                <span style="font-weight: 600; text-decoration: underline"
                  >VIVA VIDA</span
                >
              </p>
            </div>
          </div>
          <div class="card">
            <h6>Pagos a una cuenta distinta</h6>
            <hr />

            <div>
              <p>
                ¡Cuidado!, si alguien te contacta y te proporciona datos con la
                finalidad de que realices tu pago no proporciones información.
              </p>
              <p>
                Si tu crédito está vigente, ingresa a tu app "<span
                  style="font-weight: 600; text-decoration: underline"
                  >VIVA VIDA</span
                >" ahí encontraras toda la información para que puedas realizar
                tu pago.
              </p>
            </div>
          </div>
          <div class="card">
            <h6>Intimidación de revelar datos y fotos personales</h6>
            <hr />

            <div>
              <p>
                Si te localizan para amenazarte o intimidarte para compartir tus
                datos o fotos personales localizadas en tu móvil
                <span style="font-weight: 600">¡ALERTA!</span> …No somos
                nosotros.
              </p>
              <p>
                En <span style="font-weight: 600">VIVA VIDA</span>, contamos
                con políticas estrictas relacionadas con nuestros clientes y/o
                usuarios.
              </p>
              <p>
                Probablemente te hayas encontrado con conductas irregulares con
                Apps que utilizan nuestra marca haciéndose pasar por nosotros
                por tal motivoVIVA VIDA desaprueba todas las malas prácticas
                que involucran este tipo de actividades como agresividad o
                intimidación.
              </p>
            </div>
          </div>
        </div>
        <div class="wrapper_bottom">
          <div class="title">
            <img src="../assets/image/line.png" class="line" />
            Nuestra app OficialVIVA VIDA
          </div>
          <div class="sun_title">
            Con la finalidad de no caer en estafas como las app de préstamos
            fraudulentos, <br />sigue las siguientes recomendaciones:
          </div>
          <div class="display">
            <p class="card">
              Asegúrate de instalar la app oficial deVIVA VIDA desde la Play
              Store,<span style="font-weight: bold">VIVA VIDA</span>.
            </p>
            <img
              src="../assets/image/transparencia_four.png"
              alt=""
              class="transparencia_four"
            />
            <p class="card">
              Al descargar la app de
              <span style="font-weight: bold">VIVA VIDA</span>, fíjate que
              tenga nuestro icono distintivo.
            </p>
          </div>
          <hr />
          <div class="last">
            <img
              src="../assets/image/transparencia_five.png"
              alt=""
              class="transparencia_five"
            />
            <span
              >Para dudas, aclaraciones y/o agradecimientos, por favor
              escríbenos a
              <span style="text-decoration: underline; color: #41a8ef"
                >vivavida.prestamos@gmail.com</span
              >
              y un miembro del equipo se pondrá en contacto contigo.
            </span>
          </div>
        </div>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>
<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "@/components/scss/transparenciaOne.scss";

export default {
  name: "transparenciaOne",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>
