<template>
    <div class="Aviso">
        <headNav :activeNav="'privacidad'"></headNav>

        <div class="banner"></div>
        <div class="bigContent">
            <p class="bigTitle">Privacidad de Apple</p>
            <div class="content">
                <p>
                    En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”), su Reglamento y los Lineamientos del Aviso de Privacidad, estos últimos publicados en el Diario Oficial de la Federación de fecha 17 de enero de 2013, GRUPO EMPRESARIAL F.CUBE, S.A. DE C.V. SOFOM,E.N.R. y su aplicación VIVA VIDA pone a su disposición el presente Aviso de Privacidad y hace de su conocimiento los términos y condiciones siguientes:
                </p>
                <p style="font-weight: bold;">I. Del Tratamiento de los Datos Personales.  </p>
                <p>
                    GRUPO EMPRESARIAL F.CUBE, S.A. DE C.V. SOFOM,E.N.R..(en lo sucesivo GRUPO ) y su aplicación VIVA VIDA, con domicilio en la calle Versalles 15, Piso 1, Juarez, Cuauhtemoc, Ciudad de México, C.P. 06600., es responsable de la obtención, uso, divulgación, o almacenamiento, acceso, manejo, aprovechamiento, transferencia o disposición de datos personales (Adelante “Tratamiento”).
                </p>
                <p style="font-weight: bold;">II. Datos Personales sujetos a Tratamiento.</p>
                <p>
                    GRUPO  recabará Datos Personales necesarios para cumplir con su objeto social, políticas en materia de prevención y detección de operaciones con recursos de procedencia ilícita y financiamiento al terrorismo y demás políticas corporativas, ya sea personal, directamente a través de sus ejecutivos, funcionarios, empleados, promotores, o indirectamente por medio de sus asesores o comisionistas, pudiendo utilizar mecanismos en medios remotos o locales de comunicación electrónica, óptica u otra tecnología, como puede ser a través de nuestro portal web, aplicación, correo electrónico o postal, servicios en línea, vía telefónica, que le permitan recabar datos personales de manera automática y simultánea, incluyendo las referencias personales y comerciales que llegue a proporcionar el titular de la información.
                </p>
                <p>
                    Los Datos Personales podrán incluir todos o algunos de los siguientes datos de la persona física actuando de manera personal y como representante legal:
                </p>

                <ul>
                    <li>
                        <span style="font-weight: bold;">Datos de Identificación</span>:
                        nombre completo, estado civil, género, fecha de nacimiento, edad, país, identificación oficial vigente, registro federal de contribuyentes (RFC), clave única de registro de población (CURP), Firma electrónica avanzada (FIEL), correo electrónico, datos académicos y sobre características físicas como son fotografías del poseedor de los datos o su domicilio, entre otros.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos Personales:</span>
                        informaciones laboral, salarios mensuales, día de cobro, nivel educativo, informaciones bancarias, tipo de cuenta, número de tarjeta.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos Laborales:</span>
                        actividad principal, tipo de empleo, fuentes de ingresos, ingresos mensuales, dirección del centro de trabajo, teléfono del centro de trabajo, fecha de ingreso al servicio, cargo dentro de la empresa/institución, nombre del jefe directo o representante del centro de trabajo, y actividades productivas adicionales, entre otros.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos Comerciales:</span>
                        fuente de ingresos, actividades empresariales, experiencia profesional o empresarial y otros relacionados.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Cónyuge o Concubina:</span>
                        nombre completo, dirección, ocupación, teléfonos de contacto, estado civil, género, país, lugar y fecha de nacimiento, edad.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos Bancarios o patrimoniales:</span>
                        nombre de la institución bancaria donde recibe su nómina o en la cual recibe sus ingresos, número de cuenta y/o tarjeta y/o contrato, Clave Bancaria Estandarizada (CLABE), tipo de cuenta, nombre y número de la sucursal y/o plaza, entre otros, como pueden ser la huella, foto y firma digital.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de SMS:</span>
                        incluye datos de contactos, números de teléfono, mensajes SMS. (Nuestra aplicación cargará y transferirá sus datos de SMS a nuestro servidor https://data.vivavida.mx/ y https://vip.vivavida.mx para realizar gestión de riesgos y controles antifraude. Nunca compartiremos datos con terceros sin su permiso, y la recopilación de SMS se realizará siempre bajo consentimiento. Si desea eliminar sus datos privados, puede enviar una solicitud al servicio de atención al cliente a este correo electrónico: vvvida2023@gmail.com y podemos eliminarlos manualmente dentro de 3-5 días hábiles).
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Modelo y Estado del Dispositivo:</span>
                        información específica sobre su dispositivo, incluido su modelo de hardware, modelo de compilación, RAM, identificadores únicos de dispositivo como IMEI, número de serie, SSAID,AAID, información SIM.
                    </li>
                    <li>
                        <span style="font-weight: bold;">El Cooperador:</span>
                        el SDK que utilizamos para conectar con el servicio de proveedor de Deeplink se entrega a través de múltiples canales (por ejemplo, Google, Facebook) con el fin de distinguir la fuente de atribución del canal del usuario y transmitir la identidad de cada canal, pero nuestro socio Deeplink no accede ni utiliza ninguna información de la cuenta social del usuario, tampoco nuestra propia app accede ni utiliza ninguna información de la cuenta social del usuario.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Ubicación:</span>
                        recopilamos y detectamos la información de ubicación áspera de su dispositivo para brindarle servicios para su solicitud de préstamo, reducir los riesgos asociados con su solicitud de préstamo.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Cámara:</span>
                        Solicitamos permiso de cámara para tomar fotos de identificaciones y faciales, no accedemos tu galería ni foto en tu celurar. Esto asegura que obtenga una experiencia perfecta al usar la aplicación.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Almacenamiento (solo fotos de galería):</span>
                        Cuando usuarios seleccionan fotos del álbum de su teléfono móvil, la aplicación necesita acceso a la galería dentro de los archivos de multimedia del dispositivo. Este acceso facilita la selección y carga de imagenes requeridos en la solicitud de préstamo. Solamente los fotos seleccionados manualmente por los usuarios se transmitirán a nuestra aplicación de manera encriptada, y se guardarán por 1 año a partir de la fecha de carga. Nunca recopilaremos su foto sin su consentimiento, si desea eliminar los datos de fotos, puede enviar una solicitud al servicio de atención al cliente a este correo electrónico: vvvida2023@gmail.com, podemos eliminar los datos manualmente en 3-5 días hábiles.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Calendario:</span>
                        se usa para recordarle la fecha de pago para evitar el impacto del pago vencido en su crédito.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Actividad en Apps:</span>
                        la actividad en app, incluido nombre del paquete, nombre de la aplicación, la hora de la primera instalación y la hora de actualización de la aplicación.
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Contactos:</span>
                        Necesitamos que complete la información de contacto manualmente u automáticamente, incluido nombre y número de teléfono, para que podamos comunicarnos con usted de manera efectiva después de que su préstamo esté vencido y haya perdido el contacto. Si desea eliminar sus datos privados, puede enviar un correo electrónico a vvvida2023@gmail.com. Envíe una solicitud al servicio de atención al cliente y podemos eliminarla manualmente dentro de (3-5 días hábiles).
                    </li>
                    <li>
                        <span style="font-weight: bold;">Datos de Permisos dentro del Dispositivo:</span>
                        en especifico, las informaciones que emiten tras la activación de permiso de SMS, modelo y estado de dispositvo, ubicacion, informacion del usuario, camara y calendario.
                    </li>
                </ul>

                <p>
                    Todos los Datos Personales recabados serán tratados bajo estrictas medidas de seguridad, siempre garantizando su confidencialidad. GRUPO  no recabará Datos Sensibles, es decir, aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas y preferencia sexual.
                </p>
                <p>
                    <span style="font-weight: bold;">GRUPO  </span>
                    no recabará Datos Sensibles, es decir, aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas y preferencia sexual.
                </p>


                <p style="font-weight: bold;">III. Finalidades del Tratamiento.</p>

                <p>
                    Los Datos Personales que GRUPO  recabe serán utilizados para realizar la verificación y confirmación de su identidad, análisis de riesgos, y que pudieran dar origen a una relación comercial entre GRUPO  y usted como titular de los Datos Personales, siendo las finalidades:
                </p>

                <ul>
                    <li>
                        Dar cumplimiento a obligaciones contraídas con el Titular;
                    </li>
                    <li>
                        Dar cumplimiento a obligaciones regulatorias emitidas por parte de las autoridades competentes, así como atender sus requerimientos;
                    </li>
                    <li>
                        Mantener su información actualizada.
                    </li>
                    <li>
                        Identificar al Titular.
                    </li>
                    <li>
                        Evaluar riesgos y, en su caso, formalizar el contrato que derive de la relación comercial que se establezca entre las partes.
                    </li>
                    <li>
                        Realizar validaciones de identidad a través de medios biométricos y biográficos de sus clientes.
                    </li>
                </ul>

                <p style="font-weight: bold;">IV. Mecanismos para manifestación de negativa para tratamiento de sus Datos Personales.</p>

                <p>
                    En caso de que no se encuentre de conformidad con el presente Aviso de Privacidad y del tratamiento de sus Datos Personales, contará con un plazo de 5 (cinco) días hábiles contados a partir de la entrega del presente Aviso de Privacidad para que, de ser el caso, manifieste su negativa para el tratamiento de sus Datos Personales.
                </p>

                <p>
                    Para llevar a cabo el ejercicio de sus derechos de conformidad con lo señalado en el párrafo anterior, se pone a su disposición el correo electrónico vivavida.prestamos@gmail.com en el cual, dentro del asunto deberá señalar “Negativa para el Tratamiento de Datos Personales” manifestando en el cuerpo del correo sus datos de identificación a efecto de realizar lo conducente.
                </p>

                <p>
                    En caso de manifestar su negativa para el tratamiento de sus Datos Personales en términos del presente Aviso de Privacidad, no se podrá concretar la relación jurídica, en el entendido que dicha información, es requerida para la prestación del servicio.
                </p>

                <p style="font-weight: bold;">V. Transferencia de Datos.</p>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    podrá transferir sus Datos Personales a terceros, comprometiéndose a informar a dichos terceros del contenido del presente Aviso de Privacidad a fin de que tomen las medidas necesarias para respetar en todo momento los compromisos aquí descritos y asumidos por nosotros, mismos que serán extensivos para dichos terceros.
                </p>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    se cerciorará de que los terceros hayan emitido igualmente su correspondiente Aviso de Privacidad, cumpliendo así con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                </p>

                <p>
                    Los Datos Personales podrán ser transferidos a:
                </p>

                <ul>
                    <li>
                        Sociedades prestadoras de servicios, tales como: Trans Unión de México, S.A. S.I.C. (Buro de crédito), Circulo de crédito S.A. de C.V. S.I.C., Servicios Quien es Quien, S.A. de C.V., y cualquier otra que sea necesaria para verificar la identidad y veracidad de los datos proporcionados, únicamente con el propósito de dar cumplimiento a nuestras obligaciones legales y normativas derivadas de lo previsto en las leyes mexicanas.
                    </li>
                    <li>
                        Autoridades mexicanas, cuando éstas lo requieran, para validar la autenticidad de los documentos presentados como identificación oficial por la contraparte.
                    </li>
                    <li>
                        Autoridades financieras mexicanas y extranjeras, cuando éstas lo requieran con la finalidad de dar cumplimiento a nuestras obligaciones derivadas de leyes o tratados internacionales como entidad financiera, obligaciones tributarias, así como para el cumplimiento de notificaciones o requerimientos oficiales.
                    </li>
                    <li>
                        Autoridades judiciales mexicanas y extranjeras, con la finalidad de dar cumplimiento a notificaciones, requerimientos u oficios de carácter judicial.
                    </li>
                </ul>

                <p>
                    Todos los terceros mencionados pueden participar dentro de los procesos y cadena de valor ofrecida por GRUPO . En caso de ser necesaria la transferencia adicional de información que requiera su consentimiento expreso, éste se recabará con anticipación.
                </p>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    podrá proporcionar información recabada previa y durante la vigencia de la relación jurídica entre las partes y para el cumplimiento de las obligaciones derivadas de lo anterior, a las entidades financieras que sean empresas controladoras, subsidiarias o filiales de <span style="font-weight: bold;">GRUPO </span>, así como a las autoridades competentes en México conforme a la legislación aplicable así como para el cumplimiento de obligaciones legales ante autoridades administrativas y judiciales garantizando previamente que dicha transferencia otorgue la continuidad de la protección de la información y el cumplimiento a lo señalado en el presente Aviso de Privacidad..
                </p>

                <p>
                    El titular expresamente acepta y conviene que <span style="font-weight: bold;">GRUPO </span> podrá grabar cualquier comunicación telefónica que se realice entre el titular y <span style="font-weight: bold;">GRUPO </span>.
                </p>

                <p style="font-weight: bold;">
                    VI. Limitaciones en el uso y divulgación de sus Datos Personales y ejercicio de los derechos ARCO (Acceso, Rectificación, Cancelación y Oposición)
                </p>

                <p>
                    Usted tiene el derecho de acceder a sus Datos Personales que poseemos y a los detalles del tratamiento de estos, así como a rectificarlos en caso de ser inexactos o bien cancelar su uso a través de una solicitud por escrito dirigida a la siguiente dirección de correo electrónico vivavida.prestamos@gmail.com, o bien, mediante la presentación de un escrito libre dentro de las instalaciones de <span style="font-weight: bold;">GRUPO </span>, ubicadas en la Calle Versalles 15, Piso 1, Juarez, Cuauhtemoc, Ciudad de México, C.P. 06600.; en un horario de 9:00 a 15:00 hrs., Teléfono 55 7193 1822. Dicho requerimiento será atendido por la UNE (Unidad Especializada de Atención a Usuarios) en un plazo máximo de 20 (veinte) días hábiles, informándole sobre la procedencia a través del correo electrónico o el número telefónico que haya indicado en su solicitud y haciéndose efectiva, 15 (quince) días hábiles después de que se hubiera comunicado su resolución. Los plazos antes referidos podrán ser ampliados una sola vez por un periodo igual, siempre y cuando así lo justifiquen las circunstancias del caso, razones que se manifestarán al Titular, por escrito.
                </p>

                <p>
                    Para ejercer los Derechos ARCO, el Titular, deberá presentar ante <span style="font-weight: bold;">GRUPO </span>, una solicitud que deberá contener y acompañar, como mínimo, lo siguiente:
                </p>

                <div style="width: 100%;box-sizing: border-box;padding-left: 20px;">
                    <p>
                        1. El nombre completo del Titular, domicilio y cuenta de correo electrónico o cualquier medio para comunicarle la respuesta a la solicitud.
                    </p>
                    <p>
                        2.	Los documentos que acrediten la identidad o, en su caso, la representación legal del Titular.
                    </p>
                    <p>
                        3.	La descripción clara y precisa de los Datos Personales sobre los cuales se desea ejercer algún Derecho ARCO.
                    </p>
                    <p>
                        4.	Para el caso de solicitar una rectificación, se deberá acompañar la documentación que respalde la rectificación solicitada.
                    </p>
                </div>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    se reserva el derecho de solicitar información y/o documentación adicional a la mencionada para atender la solicitud.
                </p>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    no estará obligado a cancelar los datos personales cuando: (i) Se refiera a las partes de un contrato y sean necesarios para su desarrollo y cumplimiento; (ii) deban ser tratados por disposición legal; (iii) sean necesarios para cumplir con una obligación legalmente adquirida por el Titular.
                </p>

                <p>
                    La obligación de acceso a la información se dará por cumplida por parte de <span style="font-weight: bold;">GRUPO </span>, cuando ésta ponga a disposición del Titular de los Datos Personales directamente en el domicilio señalado en este inciso o se envíe al correo electrónico señalado por el Titular y elimine de su base de datos, dichos datos.
                </p>

                <p>
                    Usted manifiesta expresamente que es de su conocimiento que en cualquier momento podrá oponerse al tratamiento de los datos que no sean esenciales para la relación jurídica y/o comercial que entabla con <span style="font-weight: bold;">GRUPO </span>, lo cual podrá efectuar mediante el procedimiento aquí señalado.
                </p>

                <p>
                    En el tratamiento de sus datos personales, se prevé primordialmente que su resguardo sea contra todo daño, pérdida, alteración, destrucción, y ante todo uso, acceso o tratamiento no autorizado.
                </p>

                <p style="font-weight: bold;">
                    VII. Modificaciones al Aviso de Privacidad.
                </p>

                <p>
                    <span style="font-weight: bold;">GRUPO </span>
                    se reserva el derecho de efectuar en cualquier momento cambios o modificaciones al presente Aviso de Privacidad, ante la necesidad de proporcionarle la protección continua y adecuada a sus datos personales, así como para la atención de novedades legislativas o jurisprudenciales, políticas internas, y prácticas del mercado. Los cambios y modificaciones estarán disponibles a través de los siguientes medios: anuncios en nuestras oficinas y en el portal web de GRUPO , a través de nuestro personal que recabe sus Datos Personales. Es aconsejable revisar el Aviso de Privacidad tras la enlace https://www.vivavida.mx/ para ser enterado de cada modificación que habrá en el futuro. Si tienen cualquier duda sobre el Aviso de Privacidad, por favor no duden en ponerse en contacto con atención al cliente tras llamada (4425814085) o correo electrónico (vvvida2023@gmail.com)).
                </p>

                <p style="font-weight: bold;">
                    VIII.	Revocación del consentimiento.
                </p>

                <p>
                    Como Titular de los Datos Personales podrá revocar el consentimiento concedido a <span style="font-weight: bold;">GRUPO </span>,a fin de que se deje de hacer uso de los mismos, para lo cual se deberá seguir el procedimiento señalado en el inciso VI del presente Aviso de Privacidad.
                </p>

                <p style="font-weight: bold;">
                    IX.	Atención de Quejas y Denuncias.
                </p>

                <p>
                    En caso de que como Titular de los Datos Personales considere que su derecho a la protección de datos personales ha sido vulnerado, o bien que
                    <span style="font-weight: bold;">GRUPO </span>
                    le niega injustificadamente el ejercicio de los derechos ARCO, podrá acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales para solicitar el inicio del procedimiento de protección de derechos en términos de lo previsto en el artículo 100 del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, en sus instalaciones ubicadas en Insurgentes Av. Insurgentes Sur 3211, Insurgentes Cuicuilco, Coyoacán, 04530 Ciudad de México, CDMX, México, o bien al número telefónico +525550042400, o directamente en su página en Internet consultable en: https://home.inai.org.mx/.
                </p>

                <p>
                    La Organización Mundial de la Salud declaró “pandemia” el brote de Coronavirus o COVID-19, y anunciada por el Consejo de Salubridad del Gobierno Federal como una “enfermedad de atención prioritaria”; el 27 de marzo de 2020 se publicó en el Diario Oficial de la Federación el decreto emitido por el Titular del Ejecutivo Federal, por el que se declaran acciones extraordinarias en las regiones afectadas en todo el territorio nacional en materia de salubridad general, para combatir la enfermedad grave de atención prioritaria generada por el virus SARS-CoV2 COVID-19, por lo que atendiendo a la recomendaciones de las Autoridades para prevenir la propagación del Covid-19 y en función del semáforo epidemiológico que de tiempo en tiempo decreten la Autoridades, para ser atendido en la UNE de manera presencial deberá realizar previa cita agendada a través del Teléfono  5585263904 y al correo vvvida2023@gmail.com.
                </p>

                <p style="font-weight: bold;">
                    X. Medio de comunicación con nosotros
                </p>

                <p>
                    Si tiene cualquier duda sobre nuestro Aviso de Privacidad, por favor no dudes en ponerse en contacto con nuestro atención al cliente tras llamada, correo electrónico o whatsapp.
                </p>

                <p>
                    Atención al cliente：5585263904
                </p>

                <p>
                    Email：vvvida2023@gmail.com (Respuesta en 2 a 3 días laborales)
                </p>

                <p>
                    Whatsapp：5525278620
                </p>

                <p>
                    Tiempo laboral：De lunes a viernes 9:00-18:30, de sábado a domingo 9:00-14:30
                </p>

            </div>
        </div>
        <footPage></footPage>
    </div>
</template>

<script>
    import headNav from "@/components/headNav.vue";
    import footPage from "@/components/foot.vue";
    import "../components/scss/Aviso.scss";

    export default {
        name: "Aviso",
        components: { headNav, footPage },
        data() {
            return {};
        },
    };
</script>
