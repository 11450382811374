<template>
  <div class="transparenciaThree">
    <headNav activeNav="transparencia"></headNav>
    <img src="../assets/image/5_banner.png" class="banner" />
    <div class="wrapper">
      <div class="bigTitle">AVISO IMPORTANTE</div>
      <p>
        Te informarnos que el número de referencia o cuenta para pagar tu
        crédito es únicamente el que aparece en tu acceso a la aplicación.
       VIVA VIDA nunca te contactará por otro medio para proporcionarte una
        cuenta distinta a la que se indica en tu aplicación. Para evitar que
        seas víctima de algún fraude, te sugerimos ser cuidadoso y verificar el
        número de referencia o cuenta en la que pagarás tu crédito. Para
        cualquier duda o aclaración puedes comunicarte con nuestro departamento
        de Atención a Clientes.
      </p>
    </div>
    <footPage></footPage>
  </div>
</template>
<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "@/components/scss/transparenciaThree.scss";

export default {
  name: "transparenciaThree",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>
