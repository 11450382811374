<template>
  <div class="Aviso">
    <headNav :activeNav="'loan'"></headNav>
    <img
      src="../assets/image/3_banner.png"
      class="banner"
      alt=""
    />
    <div class="bigContent">
      <p class="bigTitle">TÉRMINOS Y CONDICIONES</p>
      <div class="content">
        <p>
          SUSU SOUL, S.A DE C.V. (en lo sucesivo “Susu ”) a través de nuestro
          portal “https://www.vivavida.mx” (en lo sucesivo la “página web”),
          así como también dentro de la aplicación que se encuentra disponible
          para los dispositivos móviles con sistema operativo Android, cuya
          denominación es “VIVA VIDA” (en lo sucesivo la “Aplicación”), se
          advierte que al momento de que el usuario realice la solicitud de un
          préstamo otorga su consentimiento pleno y sin reserva alguna primero
          al Aviso de Privacidad para el manejo de sus datos personales en los
          términos señalados en dicho aviso y en segundo se sujetara a los
          Términos y Condiciones que son aplicables al préstamo que el mismo
          podrá o no ser otorgado, es así que todos y cada uno de los documentos
          señalados al usuario podrá consultar y/o tener a su disposición dentro
          de la “página web”.
        </p>
        <p>
          Se hace la puntal aclaración que los productos y servicios que ofrece
          “Susu ”, están dirigidos única y exclusivamente a personas físicas
          mexicanas que cuenten con capacidad legal para contratar, dando como
          conclusión que expresamente están excluidos las personas que sean
          menores de edad, las aquellas declaradas judicialmente como en estado
          de interdicción u aquellas limitadas por cualquier otro contrato,
          convenio o relación comercial, estos siempre se entenderá de manera
          enunciativa más no limitativamente.
        </p>
        <p>
          Los Servicios ofrecidos por “Susu ” a través de la Página Web y/o la
          Aplicación serán válidos única y exclusivamente en el territorio de la
          República Mexicana, por lo que en caso de que la Página Web y/o la
          Aplicación pudieran ser accedidos desde diversos países alrededor del
          mundo, esto en ningún caso implicará que “Susu ” autorice o habilite
          sus servicios y/o contenidos en tales países o en diversas
          jurisdicciones.
        </p>
        <p>
          Es así que, para mayor entendimiento del usuario podemos a su
          disposición siguientes Términos y Condiciones:
        </p>
        <p>
          1. Aceptación. El usuario entiende y acepta que al ingresar y navegar
          en la Página Web y/o al registrarse a través de este medio o de la
          misma manera lo podrá realizar utilizando la aplicación, manifiesta de
          forma indubitable su voluntad plena y sin reservas de quedar sujeto a
          los presentes Términos y Condiciones, los cuales tienen carácter
          obligatorio y vinculante, por lo que, en caso de no aceptar o no estar
          de acuerdo con estos Términos y Condiciones, y/o con el Aviso de
          Privacidad de “Susu ”, deberá abstenerse de continuar navegando en la
          Página Web, así como de utilizar la Aplicación.
        </p>
        <p>
          En este sentido es responsabilidad del usuario leer y entender
          íntegramente las condiciones establecidas a continuación, antes de
          realizar cualquier gestión dentro de la Página Web y/o de registrarse
          y solicitar cualquier servicio en la Aplicación.
        </p>
        <p>
          2.Uso debido y restricciones del Software y Hardware.- Toda aquella
          persona que acceda, mediante el uso de un equipo de cómputo, tableta,
          celular y/o cualquier otro dispositivo con acceso a Internet a la
          Aplicación y/o a la Página Web, está de acuerdo en no utilizar dichos
          dispositivos, programas de cómputo, software, o cualquier otro medio
          para interferir o afectar, tanto las funcionalidades, actividades y/u
          operaciones de la Aplicación y/o del Página Web, así como las bases de
          datos y/o información que se contenga en los mismos.
        </p>
        <p>
          El usuario acepta que es responsable de contar con software y hardware
          compatible, los dispositivos necesarios, conexión a la red de
          Internet, y cualquier otro requerimiento técnico necesario para la
          correcta ejecución de la Aplicación y/o de la Página Web.
        </p>
        <p>
          El usuario entiende que si accede o utiliza la Aplicación y/o navega
          en la Página Web desde un dispositivo móvil, podrán aplicarse las
          tarifas y tasas de datos y mensajes de su proveedor de red móvil.
        </p>
        <p>
          3.Restricciones Legales en materia de Prevención de Lavado de Dinero.
          - “Susu ” y el usuario se obligan a realizar todas las actividades
          encaminadas a asegurar que todo su personal a cargo, empleados,
          socios, accionistas, administradores, clientes, proveedores, etc., y
          los recursos de estos, no se encuentren relacionados o provengan, de
          actividades ilícitas; particularmente, de lavado de activos o
          financiación del terrorismo. En todo caso, si durante el plazo de
          vigencia del préstamo se encontraren en alguna de las partes, dudas
          razonables sobre sus operaciones, así como el origen de sus activos
          y/o que alguna de ellas, llegare a resultar inmiscuido en una
          investigación de cualquier tipo (penal, administrativa, etc.)
          relacionada con actividades ilícitas, lavado de dinero o
          financiamiento del terrorismo, o fuese incluida en las listas
          nacionales o internacionales vinculantes para México de conformidad
          con el derecho internacional (listas de naciones unidas- ONU), en
          listas de la OFAC o Clinton, etc., la parte libre de reclamo tendrá
          derecho de terminar unilateralmente el mutuo con interés sin que por
          este hecho, esté obligado a indemnizar ningún tipo de perjuicio a la
          parte que lo generó.
        </p>
        <p>
          Del Registro, Solicitud de Préstamo, Otorgamiento Préstamo y Pago.
        </p>
        <p>
          I.Registro/Creación, Inhabilitación y Cancelación de cuenta.- Todo
          usuario que pretenda solicitar los productos y servicios con “Susu ”
          primero deberá registrarse a través de la Aplicación y/o en la Página
          Web, mediante la creación de un usuario y contraseña a través del
          llenado de la solicitud correspondiente en todos sus campos; pudiendo
          optar, en caso de estar habilitada la opción, el registro de su
          usuario y contraseña con el enlace con sus redes sociales, esto para
          agilizar la creación de su cuenta, dando como resultado que “Susu ”
          tome únicamente los datos necesarios para realizar su registro.
        </p>
        <p>
          El usuario se obliga a mantener la confidencialidad de su Contraseña,
          pues entiende que será responsable por todas las operaciones
          efectuadas en y desde su cuenta, toda vez que el acceso a la misma
          está restringido al ingreso y uso de su contraseña, la cual es de su
          conocimiento exclusivo.
        </p>
        <p>
          Inhabilitación de la cuenta. Sin perjuicio de otras medidas, “Susu ”
          podrá suspender, sin requisito o formalidad alguna, en forma temporal
          o inhabilitar definitivamente la cuenta del usuario y/o suspender la
          prestación de sus servicios si el usuario: (a) Trasgrediera alguna
          ley, o cualquiera de las estipulaciones de los presentes Términos y
          Condiciones. (b) Incumpliera sus compromisos como usuario. (c)
          Incurriera a criterio de Susu  en conductas o actos fraudulentos. (d)
          No pudiera verificarse la identidad del usuario o cualquier
          información proporcionada por el mismo fuere errónea o falsa.
        </p>
        <p>
          Cancelación de cuenta. El Usuario podrá cancelar en cualquier momento
          su cuenta, siempre y cuando no exista alguna obligación de pago
          pendiente a favor de “Susu ”.
        </p>
        <p>
          II.Datos Personales. - El usuario deberá llenar todos y cada uno de
          los campos en donde proporcionará los datos de identificación del
          cliente o usuario, como lo son el nombre, apellido, fecha de
          nacimiento, dirección de domicilio, número telefónico de contacto,
          nombre y números de referencias de contactos, número de cuenta y/o
          clave interbancaria en donde se depositara los recursos en dado caso
          de que sea autorizado el préstamo que solicito el usuario, en este
          acto de la misma manera el mismo usuario otorga su pleno
          consentimiento a “Susu ”, para que se de uso y tratamiento de sus
          datos personales, tal y como de la misma manera se referencia en el
          aviso de privacidad que pone a disposición del público en general a
          través de la página web de VIVA VIDA.
        </p>
        <p>
          El Usuario sabe y acepta sin limitación alguna que Susu  pueda
          consultar y almacenar sus contactos para completar automáticamente los
          datos durante el proceso de solicitud, manifestando que todos y cada
          uno de los contactos localizados en el teléfono móvil son señalados
          por el Usuario como sus referencias personales crediticias ya que para
          el acceso a nuestro servicio, requerimos referencias del solicitante
          del préstamo. En este sentido, el Usuario acepta que recopilemos y
          monitoreemos su información de contactos que incluye nombre, número de
          teléfono, tipo de cuenta, contacto modificado por última vez,
          favoritos y otros datos opcionales como relación y dirección
          estructural para permitirle autocompletar los datos durante el proceso
          de solicitud de préstamo. Esta información es necesaria para
          propósitos de análisis de riesgo, nos permite detectar referencias
          creíbles, evaluar su perfil de riesgo y determinar su elegibilidad
          para el préstamo.
        </p>
        <p>
          III.Confirmación de Usuario y Restricciones. - Una vez ingresados los
          datos requeridos, el usuario recibirá un número de confirmación al
          número de teléfono celular que indicó en la aplicación y/o página web,
          esto como parte del proceso para la autenticación de su identidad,
          dicha confirmación deberá ingresar en la Aplicación y o Pagina web
          para concluir con el registro. El envío de dichos códigos se entenderá
          por parte de “Susu ” que este fue realizado por solicitud del
          usuario.
        </p>
        <p>
          De la misma manera se le solicitara al usuario que dentro del mismo
          proceso, realice una fotografía de su credencial de elector por el
          anverso y el reverso de la misma, lo anterior es necesarios para
          comprobar por parte de “Susu ” que el usuario es mayor de edad y
          puede celebrar cualquier tipo de contrato no importando la naturaleza
          de este, subsecuentemente se le solicitara que el usuario se tome una
          fotografía tipo Selfie, esto es para comprobar de la misma manera por
          parte de “Susu ” que el usuario que registro su cuenta es la misma
          persona que está solicitando el préstamo y que posee la mayoría de
          edad.
        </p>
        <p>
          “Susu ” se reserva el derecho de solicitar documentación adicional
          con el fin de corroborar los datos ingresados en la solicitud, así
          como de suspender temporal o definitivamente las cuentas y/o negar las
          solicitudes de aquellos usuarios cuyos datos no hayan podido ser
          confirmados, sean detectados como alterados o falsificados.
        </p>
        <p>
          La cuenta dentro de la aplicación o en la página web es de carácter
          personal, única e intransferible, por lo cual, “Susu ” le tiene
          terminantemente prohibido al titular de la cuenta que un mismo usuario
          inscriba o posea más de una cuenta dentro de la página o dentro de la
          aplicación. En caso de que “Susu ” detecte distintas cuentas que
          contengan datos coincidentes o relacionados, podrá cancelar, suspender
          o inhabilitarlas, sin responsabilidad alguna.
        </p>
        <p>
          IV.Solicitud de Préstamo. - Una vez creada la cuenta por parte del
          usuario este podrá dentro de la aplicación o la página web solicitar
          el préstamo hasta por un máximo de $20,000.00 (Veinte mil pesos 00/100
          Moneda Nacional), pero como primer punto en caso de que el usuario
          pueda ser considerado por “Susu ” como sujeto a otorgamiento de
          préstamo por la cantidad máxima, el mismo usuario deberá generar un
          historial con “Susu ” como cliente que cumple con el pago de sus
          préstamos, es así que únicamente se analizara la información vertida
          por el usuario y en un primer momento se podrá autorizar el monto
          mínimo o el que la empresa considerara según el nivel de riesgo que
          representa el usuario, subsecuentemente dependiendo de cómo sea el
          comportamiento del usuario, aunado al que este concrete un buen
          historial con la empresa, es que a través de la renovación de sus
          préstamos, “Susu ” podrá autorizar al cliente montos mayores hasta
          llegar al máximo permitido de $20,000.00 (Veinte mil pesos 00/100
          Moneda Nacional).
        </p>
        <p>
          Se hace el puntual señalamiento que hasta en tanto no se haya
          notificado dentro de la aplicación o en la página web al usuario por
          parte de “Susu ” la autorización del préstamo, no se entenderá
          perfeccionado ningún otorgamiento del mismo préstamo, por dicho motivo
          el usuario entiende que la aceptación de los presentes Términos y
          Condiciones no representa ofrecimiento formal alguno por parte de “TIK
          IN” y no implica que el préstamo haya sido o que será autorizado.
        </p>
        <p>
          V.De la Obligación de Pago, los plazos para liquidar el préstamo,
          intereses ordinarios e intereses moratorios. - Todo usuario se le que
          le sea autorizado un préstamo por parte de “Susu ” no importando la
          cantidad del mismo, tiene conocimiento de estos términos y
          condiciones, el usuario al momento de aceptarlos está realizando un
          acto contractual de manera electrónica en el cual el mismo solicitante
          adquiere una deuda por el monto que le haya sido autorizado por “TIK
          IN”, es así que deberá estar consciente que por el simple hecho de que
          “Susu ” le otorga un dicho préstamo se genera un interés ponderado
          anual (CAT) del 36% sobre el total de saldos insolutos, ahora bien en
          caso de que el usuario incumpla con la obligación de pago sobre el
          total del préstamo se aumentara un interés moratorio por falta de
          pago, hasta que el usuario decida finiquitar la deuda.
        </p>
        <p>
          El usuario con previa notificación a “Susu ” a través de un escrito,
          comunicación electrónica o llamada telefónica, podrá realizar el pago
          parcial a través de transferencia electrónica y/o a través de algún
          prestador de servicios autorizados por “Susu ” y se tenga suscrito un
          convenio, dicha información será pública a través de la página web de
          “Susu ”.
        </p>
        <p>
          Por otro lado, como se menciona en el apartado IV, el plazo de pago
          del préstamo por parte del usuario corresponderá de la misma manera
          que el otorgamiento del préstamo, es así que se entenderá como plazo
          mínimo concedido para el pago de ocho días contados a partir de que el
          préstamo se encuentre a disposición del usuario, en razón de que el
          mismo genere un historial aceptable de pago para con “Susu ”, al
          momento de que este renueve o solicite otro préstamo por la cantidad
          autorizada se iría ampliando los periodos de pago, pudiendo alcanzar
          un plazo máximo hasta por noventa días, únicamente se puntualiza que
          “Susu ” realizara dicha notificación a través de la app y/o página
          web a la cuenta del usuario y si esta notificación no le apareciere se
          entenderá que el plazo mínimo de pago es por ocho días.
        </p>
        <p>
          VI.Manejo y Uso de la Cuenta no autorizado por el Usuario. - En el
          entendido del numeral I de que el usuario es responsable de la cuenta
          y de sus contraseñas, en caso de que estas fueran extraviadas, robadas
          o cualquier situación que se presentara en el momento presente o
          futuro, el usuario tiene la obligación legal de notificar a “Susu ”
          en forma inmediata por escrito, vía electrónica o telefónica cualquier
          uso no autorizado de su cuenta, así como el ingreso por terceros no
          autorizados a la misma, por lo tanto se advierte que al momento de que
          el usuario realizo el registro de la cuenta le queda terminantemente
          prohibido por parte de “Susu ” realizar la venta, cesión o
          transferencia de la cuenta.
        </p>
        <p>
          De lo anteriormente señalado “Susu ” no se hará responsable, por
          errores generales o tipográficos en los cuales incurra el usuario o
          por negligencia al usar los servicios de la Aplicación y/o la Página
          Web, de igual forma Susu  se exime de responsabilidad ante
          negligencia al dejar cualquier dispositivo electrónico, mediante el
          cual tenga acceso a la Aplicación, la Página Web y los servicios, sin
          atención alguna, reservándose el derecho de rechazar cualquier
          solicitud de registro o de cancelar una solicitud previamente
          aceptada, sin que esté obligado a exponer las razones de su decisión y
          sin que ello genere algún derecho a indemnización al usuario.
        </p>
        <p>
          VII.Envió de Publicidad y Mercadotecnia. - El usuario otorga su
          consentimiento a “Susu ” para que por cualquier medio tecnológico que
          decida le haga llegar publicidad relacionada con los Servicios que
          ofrece y/o para que sus datos personales sean utilizados con fines
          mercadotécnicos y/o publicitarios.
        </p>
        <p>
          VIII.Publicidad de los términos y condiciones a disposición del
          Público.-“Susu ” podrá establecer diversos requisitos de
          contratación/elegibilidad para cada uno de sus productos, mismos que
          pueden consultarse en la página de internet:
          https://www.vivavida.mx; así como también los términos podrán ser
          consultados en la página web.
        </p>
        <p>
          IX.Modificaciones de los Términos y Condiciones. “Susu ” podrá
          modificar los Términos y Condiciones en cualquier momento, haciendo
          públicos en la Aplicación o en la Página Web los términos modificados.
          En caso de desacuerdo del usuario respecto de los cambios realizados,
          deberá comunicarlo por correo electrónico a la siguiente cuenta
          https://www.vivavida.mx / dentro de los 5 (cinco) días siguientes a
          la publicación de las modificaciones; en ese caso será cancelada la
          cuenta del usuario siempre que no subsistan montos pendientes a favor
          de “Susu ”. Vencido este plazo, sin oposición del usuario se
          considerará que este acepta los nuevos Términos y Condiciones.
        </p>
        <p>
          X.Propiedad Intelectual. El usuario no adquirirá bajo ninguna
          circunstancia licencia o derecho alguno sobre la propiedad intelectual
          de “Susu ” por el mero uso de su App, la Página Web o de sus
          servicios; por lo tanto, su uso no podrá ser considerado como una
          autorización tácita o expresa para utilizar los sistemas y contenidos
          con algún fin diverso a los contemplados por el presente documento.
        </p>
        <p>
          El usuario reconoce y acepta que la Aplicación, la Página Web y
          cualquier software que sea necesario para permitirle acceder y usar su
          cuenta para solicitar y recibir los servicios de “Susu ”, contienen
          información confidencial de “Susu ” y/o de terceros, que se encuentra
          protegida por las leyes de propiedad intelectual y otras leyes
          aplicables, asimismo, el usuario reconoce que el contenido que consta
          en la Aplicación y la Página Web de “Susu ”, son de la titularidad y
          propiedad exclusiva de “Susu ” y por consecuencia sus derechos de uso
          y explotación que incluyen mas no se limitan a su publicación,
          reproducción, divulgación, transformación y distribución le
          corresponden únicamente a “Susu ” y están protegidas por las normas
          nacionales e internacionales de derecho de autor, marcas, patentes,
          modelos y diseños industriales.
        </p>
        <p>
          La marca denominada como “VIVA VIDA”, se encuentra protegida en
          términos de la Ley de la Propiedad Intelectual vigente. En
          consecuencia, la utilización, descarga, difusión, exhibición,
          explotación, comercialización y/o cualquier otro uso, sea parcial o
          total, de forma idéntica o que confunda en menor o mayor grado; sea
          por cualquier medio, incluyendo mas no limitándose al impreso,
          magnético, óptico, electrónico o informático, está expresamente
          prohibido sin previa autorización por escrito de “Susu ”.
        </p>
        <p>
          Cualquier contravención a lo anteriormente expuesto o a la legislación
          aplicable en materia de propiedad intelectual, industrial, derechos de
          autor será considerada y perseguida como un delito. En consecuencia,
          el usuario entiende que le queda prohibido dar uso indebido, así como
          reproducir total o parcialmente dichos sistemas y contenidos.
        </p>
        <p>
          XI.Contacto. “Susu ” estará a disposición del usuario en su domicilio
          localizado en la Versalles 15, Piso 1, Juarez, Cuauhtemoc, Ciudad de México, C.P. 06600. correo electrónico
          vivavida.prestamos@gmail.com, en un horario de 9:00 a 15:00 horas de lunes
          a viernes.
        </p>
        <p>
          XII.Notificaciones, jurisdicción y ley aplicable. Todos los avisos y
          notificaciones que las partes deban o deseen hacerse en relación con
          estos Términos y Condiciones, deberán realizarse por correo
          electrónico, en el caso de aquellos dirigidos al usuario a la cuenta
          que se haya proporcionado al momento de su registro, y para aquellos
          dirigidos a Susu  a la cuenta de contacto señalada en este
          instrumento.
        </p>
        <p>
          El Usuario señala como su domicilio para todos los efectos legales que
          tuvieran lugar el expresado en el formulario de datos para su
          registro.
        </p>
        <p>
          XIII.Para la interpretación, cumplimiento y ejecución del presente
          instrumento, las partes expresamente a las Leyes vigentes en la
          República Mexicana y se someten a la jurisdicción de los Tribunales
          Competentes con sede en la Ciudad de México, renunciando en
          consecuencia a cualquier otro fuero que, en razón de su domicilio
          presente o futuro o por cualquier otra razón pudiera corresponderles.
        </p>
        <p>
          Habiendo leído y entendido cada uno de los Términos y Condiciones, el
          usuario manifiesta su total aceptación y sujeción a cualquier
          obligación de ellos derivada, al hacer uso de la Página Web y/o la
          Aplicación.
        </p>
        <p>
          XIV.El usuario a través de estos términos y condiciones autoriza
          expresamente a Susu , para que lleve a cabo investigaciones sobre el
          comportamiento crediticio en las sociedades de información crediticia
          (SIC) que estime conveniente.
        </p>
        <p>
          Por lo cual conoce la naturaleza y alcance de la información que se
          solicitará, del uso que se le dará y que se podrá realizar consultas
          periódicas del historial crediticio.
        </p>
        <p>
          Es así que a través de la aceptación de estos términos y condiciones
          tendrán una vigencia de 3 años contados a partir de la aceptación de
          los mismos u en su caso mientras subsista la relación jurídica, por lo
          cual el usuario acepta que dicho documento quede en propiedad de TIK
          IN para efectos de control y cumplimiento del art. 28 de la LRSIC.
        </p>
        <p>
          XV.Las partes Acuerdan que el cliente podrá expresar su consentimiento
          respecto a los términos y condiciones, así como tener accesos a los
          servicios ofrecidos por Susu , mediante los medios electrónicos tales
          como OTP.
        </p>
        <p>
          Habiendo leído y entendido cada uno de los Términos y Condiciones, el
          usuario manifiesta su total aceptación y sujeción a cualquier
          obligación de ellos derivada, al hacer uso de la Página Web y/o la
          Aplicación.
        </p>
      </div>
    </div>
    <footPage></footPage>
  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";
import "../components/scss/Aviso.scss";

export default {
  name: "loan",
  components: { headNav, footPage },
  data() {
    return {};
  },
};
</script>
