<template>
  <div class="Sobre">
    <headNav :activeNav="'sobre'"></headNav>
      <div class="p1">
        <img src="@/assets/image/2_banner.png" alt="">
      </div>
      <div style="margin:0 auto; ">
        <div class="bigContent">

      <div class="content clearfix">
        <div class="left">
          <div class="title">Perfil de la empresa</div>
          <span><u>VIVA VIDA</u></span> es una plataforma que ofrece préstamos en línea rápidos, accesibles y seguros. Regístrate en segundos, aplica para un préstamo y recibe tu efectivo en minutos.Vemos el mundo diferente. Estamos motivados por una creencia fundamental en las personas, y trabajamos juntos para demostrar su potencial. Asumimos riesgos que otros no tomarían para poner más poder en las manos de nuestros clientes.
        </div>
        <div class="right">
          <div class="about_img_view">
            <img src="@/assets/image/about_img.png" alt="">
          </div>
        </div>
      </div>

    </div>
      </div>

      <!--<div class="p6">
      <div class="mainContent" style="text-align:center">
        <div class="title">
          <img src="../assets/image/kf_ico.png" alt="" />Contáctanos
        </div>
        <p class="item-title">
          Nombre de la empresa：SUSU SOUL, S.A DE C.V.
        </p>
        <p class="item-title" style="margin-top: 20px;">
          Dirección de la empresa：CLL VERSALLES 15, PISO 1 LT, JUAREZ, MEXICO, CIUDAD DE MEXICO, C.P. 06600
        </p>
        <p class="item-title" style="margin-top: 20px;">
          Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de
          atención al cliente.
        </p>
        <ul>
          <li>
            <img src="../assets/image/sj_ico.png" alt="">
            <div>
              <p>Atención al Cliente：</p>
              <p style="color:#333">5585263904</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/ico_2.png" alt="">
            <div>
              <p>Email：</p>
              <p style="color:#333">vivavida.prestamos@gmail.com</p>
            </div>
          </li>
           <li>
            <img src="../assets/image/tm_ico.png" alt="">
            <div>
              <p>Tiempo laboral：</p>
              <p style="color:#333">De lunes a viernes 9:00-18:40</p>
              <p style="color:#333">De sábado a domingo 9:00-14:40</p>
            </div>
          </li>
        </ul>
      </div>
    </div>-->

    <div class="vvcontact_wrap" style="background: #232323;">
      <div class="vvcontact_cont">
        <div class="vvcontact_title">
          <div class="vvcontact_tname">Contáctanos</div>
          <div class="vvcontact_tdesc">Si necesitas ayuda, envíanos un correo o llamar a nuestro teléfono de atención al cliente.</div>
        </div>
        <div class="vvcontact_view">
          <div class="vvcontact_vleft">
            <div class="vvcontact_vitems" style="min-height: 100px;">
              <div class="vvcontact_vitop">
                <div class="vvcontact_viticon">
                  <img src="../assets/image/viticon_01.png" alt="" />
                </div>
                <div class="vvcontact_vitname">Email</div>
              </div>
              <div class="vvcontact_vidown">
                <div class="vvcontact_vidtext">vivavida.prestamos@gmail.com</div>
              </div>
            </div>

            <div class="vvcontact_vitems" style="min-height: 100px;">
              <div class="vvcontact_vitop">
                <div class="vvcontact_viticon">
                  <img src="../assets/image/viticon_03.png" alt="" />
                </div>
                <div class="vvcontact_vitname">Atención al Cliente:</div>
              </div>
              <div class="vvcontact_vidown">
                <div class="vvcontact_vidtext">+525585263904</div>
              </div>
            </div>

            <div class="vvcontact_vitems" style="min-height: 100px;">
              <div class="vvcontact_vitop">
                <div class="vvcontact_viticon">
                  <img src="../assets/image/viticon_05.png" alt="" />
                </div>
                <div class="vvcontact_vitname">whatsapp：</div>
              </div>
              <div class="vvcontact_vidown">
                <div class="vvcontact_vidtext">+525525278620</div>
              </div>
            </div>


          </div>
          <div class="vvcontact_vright">

            <div class="vvcontact_vitems">
              <div class="vvcontact_vitop">
                <div class="vvcontact_viticon">
                  <img src="../assets/image/viticon_02.png" alt="" />
                </div>
                <div class="vvcontact_vitname">Tiempo laboral:</div>
              </div>
              <div class="vvcontact_vidown">
                <div class="vvcontact_vidtext">De lunes a viernes 9:00-18:40</div>
                <div class="vvcontact_vidtext">De sábado a domingo 9:00-14:40</div>
              </div>
            </div>

            <div class="vvcontact_vitems">
              <div class="vvcontact_vitop">
                <div class="vvcontact_viticon">
                  <img src="../assets/image/viticon_04.png" alt="" />
                </div>
                <div class="vvcontact_vitname">Especialistas en ventas:</div>
              </div>
              <div class="vvcontact_vidown">
                <div class="vvcontact_vidview">
                  <div class="vvcontact_vidvleft">
                    <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Victor Garcia:</span> </div>
                    <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Jimena Hernandez:</span> </div>
                    <div class="vvcontact_vidtext"><span style="display: inline-block; width: 170px;">Isaac Bernal:</span> </div>
                  </div>
                  <div class="vvcontact_vidvright">
                    <!--<div class="vvcontact_vidtext">+522215908643</div>-->
                    <!--<div class="vvcontact_vidtext">+527221161259</div>-->
                    <!--<div class="vvcontact_vidtext">+527351638946</div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="vvcontact_solid"></div>


    <footPage></footPage>

  </div>
</template>

<script>
import headNav from "@/components/headNav.vue";
import footPage from "@/components/foot.vue";

import "../components/scss/sobre.scss";

export default {
  name: "Sobre",
  components: { headNav, footPage },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss">
  .vvcontact_wrap{
    width: 100%;
    height: 564px;
  }
  .vvcontact_solid{
    width: 100%;
    height: 1px;
    background: #2f2f2f;
  }
  .vvcontact_cont{
    width: 1290px;
    height: 564px;
    margin: 0 auto;
  }
  .vvcontact_title{
    width: 100%;
    height: auto;
    padding-top: 84px;
  }
  .vvcontact_tname{
    width: 100%;
    height: 34px;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 24px;
    text-align: left;
  }
  .vvcontact_tdesc{
    width: 100%;
    height: auto;
    font-size: 16px;
    color: #828282;
    line-height: 22px;
    text-align: left;
  }
  .vvcontact_view{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding-top: 84px;
  }
  .vvcontact_vleft{
    width: 645px;
    height: auto;
  }
  .vvcontact_vright{
    width: 400px;
    height: auto;
  }
  .vvcontact_vitems{
    width: 100%;
    height: auto;
    min-height: 126px;
  }
  .vvcontact_vitop{
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .vvcontact_viticon{
    width: 20px;
    height: 20px;
  }
  .vvcontact_viticon img{
    display: block;
    width: 20px;
    height: 20px;
  }
  .vvcontact_vitname{
    width: auto;
    height: 25px;
    font-size: 18px;
    color: #828282;
    line-height: 25px;
    text-align: left;
    margin-left: 8px;
  }
  .vvcontact_vidown{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    padding: 10px 0 0 28px;
  }
  .vvcontact_vidtext{
    width: 100%;
    height: 35px;
    font-weight: 400;
    font-size: 18px;
    color: #DEDEDE;
    line-height: 25px;
    text-align: left;
  }
  .vvcontact_vidview{
    width: 310px;
    height: auto;
    display: flex;
    justify-content: space-between;
  }
</style>
